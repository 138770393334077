import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./css/Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <div className={classNames("header-container", { fixed: isFixed })}>
        <img src="/images/logo.png" alt="Agrotek Logo" className="logo" />
        <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="#home" onClick={toggleMenu}>
                Inicio
              </a>
            </li>
            <li>
              <a href="#products" onClick={toggleMenu}>
                Productos
              </a>
            </li>
            <li>
              <a href="#about" onClick={toggleMenu}>
                Nosotros
              </a>
            </li>
            <li>
              <a href="#contact" onClick={toggleMenu}>
                Contacto
              </a>
            </li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
