import React from "react";
import "./css/Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p>&copy; 2025 Agrotek. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
