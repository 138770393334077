import React from "react";
import { Link } from "react-router-dom";
import { useAOS } from "../AOSContext";
import "./css/Products.css";

const Products = ({ producto }) => {
  /* 
    <a href="#" className="btn">
        Mas informacion
      </a>*/
  //agregar esto en donde sea el href <p>{producto.description}</p>
  return (
    <div className="product-card" data-aos="fade-up" data-aos-duration="1000">
      <img src={producto.imageSrc} alt="Producto 1" />
      <h3>{producto.name}</h3>
      <ul style={{ listStyleType: "disc", padding: "0 0 0 20px" }}>
        {producto.characteristics.map((producto, index) => (
          <li key={index} style={{ textAlign: "left" }}>
            {producto}
          </li>
        ))}
      </ul>
      <Link to="/duratek" state={{ producto }}>
        <button className="btn">Más información</button>
      </Link>
    </div>
  );
};

export default Products;
