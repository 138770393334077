import React from "react";
import { useLocation } from "react-router-dom";
import "./css/Duratek.css";

const Duratek = () => {
  const location = useLocation();
  const { producto } = location.state || {};
  return (
    <div className="duratek-container">
      <div className="duratek-content">
        <h1>Duratek</h1>
        {producto ? (
          <>
            <p>{producto.description}</p>
          </>
        ) : (
          <p>No se encontró información del producto.</p>
        )}
        <strong>Beneficios:</strong>
        <ul style={{ listStyleType: "disc" }}>
          {producto.benefits.map((producto, index) => (
            <li key={index} style={{ textAlign: "left" }}>
              {producto}
            </li>
          ))}
        </ul>
        <img className="image-card" src={producto.imageSrc2} alt="Duratek" />
        <img src={producto.diagramImgSrc} alt="Duratek" />
      </div>
    </div>
  );
};

export default Duratek;
