import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Products from "./components/Products";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import WhatsAppButton from "./components/WhatsAppButton";
import Duratek from "./components/Duratek";
import "./App.css";

const productos = [
  {
    id: 1,
    name: "Duratek SS",
    description:
      "El cangilón DURATEK tiene el diseño original de los primeros cangilones plásticos americanos que han funcionado desde hace 50 años. Esta diseñado con un ángulo de descarga de 45º y puede instalarse muy cerca uno de otro sobre la banda, dando una descarga muy superior a la de otros cangilones. El cangilón DURATEK tiene un diseño más moderno sin las 'orejas' de los lados y con esquinas y labio reforzados para una mayor duración. El espaciado vertical estandar entre cangilones es la proyección +25 mm. Los usos más comunes incluyen MANI, granos, harinas, maíz, trigo, azucar y otras aplicaciones en la agricultura. El cangilón DURATEK tiene el diseño más versatil que se desempeña bien en una amplia gama de velocidades y aplicaciones.",
    characteristics: [
      "Livianos",
      "Memoria de forma",
      "Descarga más limpia",
      "Diseño más moderno, refuerzos en labio y esquinas",
      "No corrosivo, no provoca chispas",
    ],
    benefits: [
      "Incrementa la capacidad del elevador",
      "Reduce el mantenimiento del elevador",
      "Extiende la vida útil del cangilon",
      "Disminuye el tiempo de interrupción por mantenimiento",
      "Resiste enganches",
    ],
    imageSrc: "/images/duratek.jpg",
    imageSrc2: "/images/duratek2.jpg",
    diagramImgSrc: "/images/duratek_diagram.gif",
  },
  {
    id: 2,
    name: "Eurotek",
    description: "Diseñados para transporte de semillas de alto rendimiento.",
    characteristics: [
      "Esquinas más gruesas",
      "Más capacidad",
      "Descarga más limpia",
      "Alto impacto, resistente a la abrasión",
      "No corrosivo, no provoca chispas",
    ],
    benefits: [],
    //price en algun momento?
    imageSrc: "/images/eurotek.jpg",
    imageSrc2: "/images/eurotek2.jpg",
    diagramImgSrc: "/images/eurotek_diagram.gif",
  },
];

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Header />
              <Hero />
              <section id="products" className="products">
                <div className="container">
                  <h2>Nuestros Productos</h2>
                  <div className="product-grid">
                    {productos.map((producto) => (
                      <Products producto={producto} />
                    ))}
                  </div>
                </div>
              </section>
              <About />
              <WhatsAppButton />
              <Contact />
              <Footer />
            </div>
          }
        />
        <Route path="/duratek" element={<Duratek />} />
      </Routes>
    </Router>
  );
}

export default App;
